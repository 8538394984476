<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <mdb-row>
                    <mdb-col md="9">
                        <mdb-input label="商品名稱"
                                   v-model="prod.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-switch class="mt-4"
                                    color="primary"
                                    offLabel=""
                                    :onLabel="prod.active ? '啟用' : '停用'"
                                    :checked="prod.active"
                                    @getValue="val=>prod.active=val" />
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="6">
                        <mdb-input label="商品編號"
                                   v-model="prod.code"
                                   :isValid="code_check"
                                   customValidation
                                   isValidated
                                   invalidFeedback="編號重複!"
                                   @change="check_product_code()"></mdb-input>
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-select label="供應商"
                                    search
                                    v-model="supplier"
                                    @getValue="val=>prod.id_supplier=val"></mdb-select>
                    </mdb-col>
                </mdb-row>
                <mdb-row>
                    <mdb-col md="6">
                        <mdb-input label="商品成本"
                                   type="number"
                                   :min="0"
                                   v-model.number="prod.cost"></mdb-input>
                    </mdb-col>
                    <mdb-col md="6">
                        <mdb-input label="建議售價"
                                   type="number"
                                   :min="0"
                                   v-model.number="prod.price"></mdb-input>
                    </mdb-col>
                    <mdb-col col="12">
                        <mdb-select label="分類"
                                    multiple
                                    selectAll
                                    selectAllPlaceholder="選取全部"
                                    search
                                    searchPlaceholder="搜尋分類"
                                    placeholder="請選取分類"
                                    @getValue="get_product_class"
                                    :options="product_classList" />
                    </mdb-col>
                    <mdb-col md="4">
                        <mdb-switch @getValue="val=>prod.forall=val"
                                    offLabel="適用全部機種"
                                    :onLabel="prod.forall ? '是' : '否'"
                                    :checked="prod.forall" />
                    </mdb-col>
                    <mdb-col md="8">
                        <mdb-select label="適用維修品項"
                                    multiple
                                    selectAll
                                    selectAllPlaceholder="選取全部"
                                    search
                                    searchPlaceholder="搜尋維修品項"
                                    placeholder="請選取維修品項"
                                    @getValue="get_repair_target"
                                    :options="repair_targetList"
                                    v-show="!prod.forall" />
                    </mdb-col>
                </mdb-row>

            </mdb-card-body>
        </mdb-card>
        <div class="text-right mb-4">
            <mdb-btn color="default"
                     @click="savedata()">
                <i class="fa fa-save"></i>
                &nbsp;儲存
            </mdb-btn>
        </div>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbSelect,
  mdbBtn,
  mdbSwitch,
} from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbSelect,
    mdbCol,
    mdbBtn,
    mdbSwitch,
  },
  props: {
    id_product: {
      default: 0,
    },
  },
  data() {
    return {
      prod: {
        class: [],
      },
      code_check: false,
      data: {},
      supplier: [],
      product_classList: [],
      repair_targetList: [],
    };
  },
  watch: {
    "$route.query.id_product"() {
      this.get_data();
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {
              id: {
                type: 0,
                value: this.id_product,
              },
            },
          },
          {
            name: "product_class",
            data: {},
            sort: {
              name: 1,
            },
          },
          {
            name: "product",
          },
          {
            name: "supplier",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
          },
          {
            name: "repair_target",
            data: {
              active: {
                type: 0,
                value: 1,
              },
            },
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (this.id_product > 0) {
            res.data.productList[0].active =
              res.data.productList[0].active == "1";
            res.data.productList[0].forall =
              res.data.productList[0].forall == "1";
            vue.prod = res.data.productList[0];
          } else {
            res.data.productEmpty.active = true;
            res.data.productEmpty.forall = false;
            res.data.productEmpty.class = "[]";
            res.data.productEmpty.repair_target = "[]";
            vue.prod = res.data.productEmpty;
          }
          vue.prod["class"] = JSON.parse(vue.prod["class"]);
          vue.prod.repair_target = JSON.parse(vue.prod.repair_target);
          vue.product_classList = res.data.product_classList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = vue.prod.class.indexOf(item.id) > -1;
            return item;
          });
          vue.repair_targetList = res.data.repair_targetList.map((item) => {
            item.value = item.id;
            item.text = item.name;
            item.selected = vue.prod.repair_target.indexOf(item.id) > -1;
            return item;
          });
          vue.supplier = res.data.supplierList.map((item) => {
            return {
              value: item.id,
              text: item.name,
              selected: item.id == vue.prod.id_supplier,
            };
          });
        });
    },
    // 檢查商品編號是否有重複
    check_product_code() {
      let vue = this,
        query = [
          {
            name: "product",
            data: {
              code: {
                type: 0,
                value: vue.prod.code,
              },
              id: {
                type: 1,
                value: vue.prod.id ? vue.prod.id : "",
              },
            },
            num: 1,
          },
        ];
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          vue.code_check = res.data.productNumber == "0";
        });
    },
    get_product_class() {
      let vue = this,
        classList = [];
      vue.product_classList.forEach((item) => {
        if (item.selected) {
          classList.push(item.id);
        }
      });
      vue.prod.class = classList;
    },
    get_repair_target() {
      let vue = this,
        repair_targetList = [];
      vue.repair_targetList.forEach((item) => {
        if (item.selected) {
          repair_targetList.push(item.id);
        }
      });
      vue.prod.repair_target = repair_targetList;
    },
    // 儲存資料
    savedata() {
      let vue = this,
        msg = "";
      switch (true) {
        case !vue.prod.name:
        case vue.prod.name.trim() == "":
          msg = "請填寫 商品名稱";
          break;
        case !vue.code_check:
          msg = "商品編號不可重複";
          break;
      }
      if (msg == "") {
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "product/save_process",
              data: {
                data: JSON.stringify(vue.prod),
              },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              vue.$swal
                .fire({
                  icon: "success",
                  title: "儲存完成!",
                  timer: 1500,
                  showConfirmButton: false,
                })
                .then(() => {
                  // console.info(res.data);
                  if (vue.prod.id != res.data.id_product) {
                    location.reload();
                  } else {
                    vue.$router.go(-1);
                  }
                });
            }
          });
      } else {
        // console.info(vue.prod);
        vue.$swal.fire({
          icon: "error",
          title: msg,
          timer: 1500,
          showConfirmButton: false,
          toast: true,
        });
      }
    },
  },
};
</script>